import React, {Component} from 'react'
import {Helmet} from 'react-helmet'
import {Layout} from '../layouts'
import GoogleMapReact from 'google-map-react';
import {RequestRates} from '../containers'

import {MdPlace} from 'react-icons/md'
import {colorAccent} from "../theme";

const Marker = () => (
    <MdPlace
        color={colorAccent}
        size={`2.5rem`}
    />
);

class Contact extends Component {
    static defaultProps = {
        center: {
            lat: 37.9403882,
            lng: 23.704908
        },
        zoom: 15
    };

    render() {
        return (
            <Layout location={this.props.location} full>
                <Helmet defer={false}>
                    <title>{`Επικοινωνία`}</title>
                </Helmet>
                <RequestRates title={`Επικοινωνία`}/>
                <div style={{height: '40vh', width: '100%'}}>
                    <GoogleMapReact
                        bootstrapURLKeys={{key: `AIzaSyBoOk_efynaEN_bqLEjez86BJw3jCzAFFk`}}
                        defaultCenter={this.props.center}
                        defaultZoom={this.props.zoom}
                        yesIWantToUseGoogleMapApiInternals
                    >
                        <Marker
                            lat={37.9403882}
                            lng={23.704908}
                        />
                    </GoogleMapReact>
                </div>
            </Layout>
        )
    }
}

export default Contact
